<template>
	<div>
		<img class="banner" style="width: 100%;" src="../assets/banner.png" alt="">
		<!-- 留言 -->
		<div class="formbox">
			<div class="title">留言</div>
			<div class="titles">LEAVE A MESSAGE</div>
			<div class="fcs">
				<input v-model="form.name" placeholder="姓名" maxlength="5"></el-input>
				<input v-model="form.phone" placeholder="联系方式" maxlength="15"></el-input>
			</div>
			<textarea v-model="form.dutuies" placeholder="留言..."></textarea>
			<div class="btnbox fcs">
				<div class="btn1 fcc" @click="define">提交</div>
				<div class="btn2 fcc" @click="chongzhi">重置</div>
			</div>
		</div>
		<div style="height: 80px;"></div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form: {
					name: '',
					phone: '',
					dutuies: ''
				},
				info: {}

			}
		},
		created() {
			this.getinfo();
		},
		methods: {
			//提交
			define() {
				this.$confirm('确定提交吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					  this.$https
			.get(`/api/pc/liuyan?name=${this.form.name}&phone=${this.form.phone}&message=${this.form.dutuies}`,).then(res => {
						if (res.code == 1) {
							this.$message({
								type: 'success',
								offset: 80,
								duration: 4000,
								message: '提交成功!'
							});
							this.form.name = '';
							this.form.phone = '';
							this.form.dutuies = '';
							// location. reload()
						} else {
							this.$message({
								type: 'error',
								offset: 80,
								message: res.msg
							});
						}
					})
				})
			},
			//重置
			chongzhi() {
				this.form.name = '';
				this.form.phone = '';
				this.form.dutuies = '';
			},
			//获取信息
			getinfo() {
				this.$api.home.lxwm().then(res => {
					if (res.code == 1) {
						this.info = res.data;
					}
				})
			},
			changenav(path) {
				this.path = path;
				this.$router.push({
					path: path
				})
				sessionStorage.setItem('pagepath', path)
			}
		}
	}
</script>

<style scoped>
	.formbox {
		width: 1040px;
		margin: 54px auto;
		position: relative;
	}

	.formbox .title {
		font-size: 48px;
		color: #1E2832;
		text-align: center;
		margin-bottom: 85px;
	}

	.formbox .titles {
		font-size: 68px;
		font-family: Adobe Heiti Std;
		font-weight: normal;
		color: #1E2832;
		opacity: 0.1;
		position: absolute;
		top: 0;
		left: 0;
	}

	.formbox input {
		width: 512px;
		height: 62px;
		font-size: 18px;
		border: 2px solid #e5e5e5;
		border-radius: 4px;
		outline: none;
		box-sizing: border-box;
		padding: 0 33px;
	}

	.formbox textarea {
		width: 1040px;
		height: 163px;
		font-size: 18px;
		border: 2px solid #e5e5e5;
		border-radius: 4px;
		outline: none;
		box-sizing: border-box;
		padding: 18px 33px;
		margin-top: 19px;
	}

	.formbox .btnbox {
		width: 451px;
		margin: 0 auto;
		margin-top: 70px;
	}

	.formbox .btnbox .btn1 {
		width: 175px;
		height: 50px;
		background: #0049C2;
		font-size: 18px;
		color: #ffffff;
		border-radius: 50px;
	}

	.formbox .btnbox .btn2 {
		width: 175px;
		height: 50px;
		border: #1E2832 solid 2px;
		font-size: 18px;
		color: #131A21;
		border-radius: 50px;
	}
</style>